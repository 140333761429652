import { render, staticRenderFns } from "./CMBody.vue?vue&type=template&id=93247c88&scoped=true&lang=pug&"
import script from "./CMBody.vue?vue&type=script&lang=js&"
export * from "./CMBody.vue?vue&type=script&lang=js&"
import style0 from "./CMBody.vue?vue&type=style&index=0&id=93247c88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93247c88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDialog,VSpacer})
