<template lang="pug">
.cm-body(:class="{'without-course-config': !activeSingleCourse || !activeSingleCourse.ID || (isMultipleMode && activeSingleCourse.ID)}")
  .cm-body__wrapper
    filters(@filterChanged="loadList" portal-chip-name="cm-filters-chips")
    grouped-table(
      :headers="headers"
      item-key="ID"
      hide-default-footer
      hide-default-header
      :loading="loading"
      class="app-table cm-body__table v-data-table theme--light table-q-management"
      :list="list"
      :server-items-length="list.length"
      :mobile-breakpoint='NaN'
      multi-sort=false
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      :expanded.sync="expanded"
      single-expand=true
      @update:sort-desc="updateSorting"
      show-select
      v-model="activeItems"
    )
      template(v-slot:top)
        .pr-4.pt-2.pb-2.d-flex.cm-top.d-flex.justify-between
          tabs(:key="`${$route.query.city}-${$route.query.course_id}`" :candidatesCount="candidatesCount" @changed="onTabsChange")
        .cm-actions.px-4.pt-2.pb-1(v-show="courseDays.length && activeCourses && activeCourses[0].date && !isMultipleMode")
          div(v-for="courseDay in courseDays").q-course-day {{ courseDay.date | formattedDate }} -
            |&nbsp;{{ courseDay.name ? courseDay.name + '&nbsp;-' : '' }}
            |&nbsp;{{ courseDay.meeting_space ? courseDay.meeting_space.name : 'no meeting space' }} - &nbsp;
            span.success--text {{ courseDay.will_come_candidates_count}}
        div(:class="{'select-bg-color': activeItems.length && !isMultipleMode, 'multiple-select-bg-color': activeItems.length && isMultipleMode }").cm-actions.px-4.pt-2.pb-1.d-flex
          div(v-if="activeItems.length").app-table__selected-items-title.mr-2 {{ activeItems.length}} items selected
          select-all(
            v-if="showSelectAll"
            @change="selectAllClick"
            :activeItemsCount="activeItems.length"
            :itemsToSelectCount="pagination.count"
          ).mr-2
          v-spacer(v-if="activeItems.length")
          acceptBulk(:activeItems="activeItems" v-if="!isReservedPage && !isExchangeQueuePage").mr-2
          candidates-service
            markAsProcessedBulk(:activeItems="activeItems" @clearSelection="clearSelection").mr-3
          removeFromAcceptance(
            :disabled="!activeItems.length"
            :activeItems="activeItems"
            v-if="!isReservedPage && !isExchangeQueuePage"
          ).mr-2
          try-to-reserve(:activeItems="activeItems" :disabled="!activeItems.length" v-if="isReservedPage").mr-2

          handleFailedCandidatesProvider(v-slot="handleFailed")
            span.contents
              v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
                handleFailedBody(
                  :list="handleFailed.failedCandidates"
                  :error="handleFailed.error"
                  :totalSendCount="handleFailed.totalSendCount"
                  @input="handleFailed.toggle"
                )
              span.contents
                mijn-bulk(
                  v-if="!isReservedPage && !isExchangeQueuePage"
                  :disabled="!activeItems.length"
                  :activeItems="activeItems"
                  @send="handleFailed.check($event, activeItems.length)"
                ).mr-2
                bulk-assign-labels(:disabled="!activeItems.length" :activeItems="activeItems").mr-2
                bulk-assign-labels(btnText="Unassign labels" mode="remove" :disabled="!activeItems.length" :activeItems="activeItems").mr-2
                addRequests(
                  :disabled="!activeItems.length"
                  :activeItems="activeItems"
                  :defaultCourse="activeSingleCourse"
                  @send="handleFailed.check($event, activeItems.length)"
                  v-if="!disabledAddExam && !isExchangeQueuePage"
                ).mr-2
                removeRequests(
                  :disabled="!activeItems.length"
                  :activeItems="activeItems"
                  :defaultCourse="activeSingleCourse"
                  @send="handleFailed.check($event, activeItems.length)"
                  v-if="isReserveQueuePage || isExchangeQueuePage"
                ).mr-2
                addRequests(
                  v-if="isReservedPage || isExchangeQueuePage"
                  title="Add exchange exam requests"
                  :disabled="!activeItems.length"
                  :activeItems="activeItems"
                  :defaultCourse="activeSingleCourse"
                  @send="handleFailed.check($event, activeItems.length)"
                  exchange
                ).mr-2
                exchange(
                  v-if="isReservedPage || isExchangeQueuePage || isReserveQueuePage"
                  :disabled="!activeItems.length"
                  :activeItems="activeItems"
                  :title="isReserveQueuePage ? 'Suggest exam': 'Suggest exchange'"
                  @send="handleFailed.check($event, activeItems.length)"
                  :isExchange="isReserveQueuePage ? false : true"
                ).mr-2
                quickReserve(
                  v-if="!isReservedPage && !isExchangeQueuePage"
                  :activeItems="activeItems"
                  :disabled="!activeItems.length"
                  :city="+$route.query.city"
                  @create="quickReserveHandler(handleFailed.check, $event)"
                  isReserve
                ).mr-2
                quickReserve(
                  :activeItems="activeItems"
                  :disabled="!activeItems.length"
                  @create="quickReserveHandler(handleFailed.check, $event)"
                  title="Quick Exchange"
                  :city="+$route.query.city"
                  isExchange
                  v-if="isReservedPage || isExchangeQueuePage"
                )
          sync(
            :activeItems="activeItems"
            :disabled="!activeItems.length"
          ).mr-2
          assign-candidates-to-journey(
            v-if="isReservedPage"
            :activeItems="activeItems"
            :disabled="!activeItems.length"
          )
            template(v-slot:activator="createJourney")
              v-btn(
                small
                @click="createJourney.open"
                color='primary'
                :disabled="!activeItems.length"
                outlined
              ).mr-2 Assign to Journey
          changeCourseBulk(
            v-if="isReservedPage"
            :activeItems="activeItems"
            :disabled="!activeItems.length"
            @send="onChangeCourseBulk"
          ).mr-2

          copy(:activeItems="activeItems" :disabled="!activeItems.length" :list="list" multiple)
          candidate-color.mr-2(:activeItems="activeItems" :disabled="!activeItems.length" :page="COLOR_PAGE")


      template(v-slot:header="headerRow")
        header-row(:headerRow="headerRow")

      template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
        course-row(
          :item="item"
          :index="index"
          :key="item.ID"
          :isSelected="isSelected"
          :select="select"
          :expand="expand"
          :isExpanded="isExpanded"
          :activeItems="activeItems"
          :primaryPagination="primaryPagination"
          :labels="labels"
          @click:payments="paymentsOpen"
          @onGoToEdit="goToEdit"
          @selectedWithShiftkey="extendedSelection($event, list)"
          @onWillComeUpdate="$emit('needToUpdate:courses')"
        )

      template(v-slot:expanded-item="{ headers, item, expand }")
        td(:colspan="headers.length").pl-0.pr-0.app-table__expand
          candidate-item(
            :ID="item.ID"
            @close="hideExpandBy(expand)"
            ref="expand"
            :key='item.ID'
            @saved="updateTableDataBy(item, $event)"
          ).pb-12

      template(v-slot:footer="footerOptions")
        .cm-body__pagination
          slot(name="footer" :footerOptions="footerOptions")

    payments-wrapper(ref="payments")
</template>

<script>
import { mapState, mapGetters } from "vuex"
import expandMixin from "@/app/admin/mixins/expand.mixin"
import { CANDIDATE_PAGE } from '../../candidates/core/candidates-const'
import { CM_HEADERS } from "../core/cm-const"
import CmBodyMixin from "../core/mixins/cmBody.mixin"

export default {
  mixins: [expandMixin, CmBodyMixin],

  props: {
    courseDays: Array
  },

  watch: {
    '$route.query': function () {
      this.activeItems = []
    },
    isMultipleMode() {
      if (!this.isMultipleMode) this.activeItems = []
    }
  },

  created() {
    this.autocompleteRepo.loadDataByFields()
    this.initSortModel()
  },

  mounted() {
    this.loadLabels({cache: true})
  },

  computed: {
    ...mapState('cm', {
      isMultipleMode: state => state.isMultipleMode,
    }),

    headers() {
      return CM_HEADERS
    },

    ...mapGetters({
      labels: 'labels/items',
      activeSingleCourse: 'cm/activeSingleCourse'
    }),

    disabledAddExam() {
      return !this.$route.query.page_name || this.$route.query.page_name === CANDIDATE_PAGE.RESERVED_ALL;
    },

    showChangeCourse() {
      return this.$route.query.page_name !== CANDIDATE_PAGE.MIJN_CBR_QUEUE && this.$route.query.page_name !== CANDIDATE_PAGE.TOP_CBR_QUEUE
    },

    showSelectAll() {
      return this.activeItems.length && this.pagination && this.pagination.count > this.pagination.size
    },

    isReservedPage() {
      return this.$route.query.page_name === CANDIDATE_PAGE.RESERVED_ALL ||
        this.$route.query.page_name === CANDIDATE_PAGE.RESERVED_ALL_WILL_COME ||
        this.$route.query.page_name === CANDIDATE_PAGE.RESERVED_ALL_WILL_NOT_COME
    },

    isReserveQueuePage() {
      return this.$route.query.page_name === CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE
    },

    isExchangeQueuePage() {
      return this.$route.query.page_name === CANDIDATE_PAGE.TOB_CBR_EXCHANGE_QUEUE
    },

    isMijnPage() {
      return this.$route.query.page_name === CANDIDATE_PAGE.MIJN_CBR_QUEUE
    },
  },

  methods: {
    extendedSelection({item, index}, list) {
      let activeItemsIndex = this.activeItems.map(item => list.findIndex(_item => _item.ID === item.ID))
      let itemUpperToSelectedIndex = -1
      activeItemsIndex.forEach(activeIndex => {
        if ((index > activeIndex) && (itemUpperToSelectedIndex < activeIndex)) itemUpperToSelectedIndex = activeIndex
      })
      if (itemUpperToSelectedIndex < 0) return
      for (let i = itemUpperToSelectedIndex + 1; i < index; i++) {
        this.activeItems.push(list[i])
      }
    },

    async quickReserveHandler(callback, res) {
      callback(res, this.activeItems.length)
      let query = {...this.$route.query}
      delete query.size
      await this.courseListDetailed({query: query})
    }
  },

  provide: function () {
    return {
      autocompleteRepo: this.autocompleteRepo,
      svc: () => this.candidatesSvc
    }
  },

  components: {
    courseRow: () => import('./CMCourseRow.vue'),
    bulkAssignLabels: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesBulkAssignLabels.vue'),
    PaymentsProvider: () => import('@/app/admin/modules/candidates/components/payments/CanidatesPaymentsProvider'),
    Payments: () => import('@/app/admin/modules/candidates/components/payments/CandidatesPayments.vue'),
    paymentsCancelProvider: () => import('@/app/admin/modules/candidates/components/payments/CandidatesPaymentCancelProvider.vue'),
    copy: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesCopy.vue'),
    candidateItem: () => import('@/app/admin/modules/candidates/components/item/CandidateItem.vue'),
    addRequests: () => import('@/app/admin/modules/candidates/components/CandidatesAddExamRequest.vue'),
    tabs: () => import('./CMTabs.vue'),
    acceptBulk: () => import('@/app/admin/modules/candidates/components/CandidateAcceptActivatorBulk.vue'),
    markAsProcessedBulk: () => import('./CMMarkAsProceedBulk.vue'),
    mijnBulk: () => import('@/app/admin/modules/candidates/components/CandidatesMIJNBulk.vue'),
    handleFailedCandidatesProvider: () => import('@/app/admin/modules/candidates/components/CandidatesHandleFailedProvider'),
    handleFailedBody: () => import('@/app/admin/modules/candidates/components/CandidatesHandleFailedBody.vue'),
    selectAll: () => import('@/app/admin/modules/candidates/components/CandidateSelectAll.vue'),
    quickReserve: () => import('../components/CMQuickReserve.vue'),
    removeRequests: () => import('@/app/admin/modules/candidates/components/CandidatesRemoveExamRequest.vue'),
    tryToReserve: () => import('@/app/admin/modules/candidates/components/CandidatesTryToReserve.vue'),
    removeFromAcceptance: () => import('@/app/admin/modules/candidates/components/CandidatesRemoveFromAcceptance.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    exchange: () => import('@/app/admin/modules/candidates/components/CandidatesExchange.vue'),
    changeCourseBulk: () => import('@/app/admin/modules/candidates/components/CandidatesChangeCourseBulk.vue'),
    sync: () => import('@/app/admin/modules/candidates/components/CandidatesSync.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    headerRow: () => import('./CMHeaders.vue'),
    filters: () => import('./cm_filters/CMFilters.vue'),
    groupedTable: () => import('@/components/global/GroupedTable.vue'),
    paymentsWrapper: () => import('@/app/admin/modules/candidates/components/payments/CandidatePaymentWrapper.vue'),
    candidatesService: () => import('@/app/admin/modules/candidates/components/CandidatesProvider.vue'),
    assignCandidatesToJourney: () => import('@/app/admin/modules/course_day/components/journey/JourneyAssignCandidates.vue')
  }
}
</script>

<style lang="scss" scoped>
.cm-body::v-deep {
  &__table {
    position: relative;
    overflow: auto;
    height: calc(100% - 64px);
    box-shadow: unset;

    @extend %visible-scrollbar;
  }
}

.cm-body {
  overflow: auto;
  min-height: calc(100vh - 135px);
  width: calc(100% - 270px);
  height: calc(100vh - 135px);

  &.without-course-config {
    width: calc(100% - 190px);
  }

  &__table {
    position: relative;
    overflow: auto;
    height: calc(100% - 64px);
    box-shadow: unset;

    @extend %visible-scrollbar;
  }

  &__wrapper {
    width: 100%;
    height: 100%;

    &::v-deep {
      .v-data-table__wrapper {
        overflow: visible;

        > table {
          width: auto;
        }
      }
    }
  }

  &__pagination {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    width: 100%;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
}

.app-table__selected-items-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.multiple-select-bg-color .app-table__selected-items-title {
  color: white;
}

.app-table::v-deep table {
  min-width: $table-min-width;
  table-layout: fixed;
  & th:first-child {
    width: 30px !important;
  }

  & th:last-child,
  td:last-child {
    padding-right: 8px;
  }

  .cell-btns {
    width: 40px;
  }

  .cell {
    &-name {
      width: 127px;
    }

    &-index {
      width: 36px;
    }

    &-cbr {
      width: 70px;
    }

    &-appid {
      width: 70px;
    }

    &-reserved {
      width: 122px;
    }

    &-bday {
      width: 95px;
    }

    &-hold {
      width: 68px;
    }

    &-status {
      width: 61px;
    }

    &-delete {
      width: 25px;
    }

    &-error {
      width: 100px;
    }

    &-email {
      width: 28px;
    }

    &-dates {
      width: 120px;
    }

    &-placed-paid {
      width: 90px;
    }

    &-reservation {
      width: 150px;
    }

    &-product {
      width: 80px;
    }

    &-email {
      width: 28px;
    }

    &-number {
      width: 100px;
    }

    &-app-note {
      width: 80px;
    }

    &-note {
      min-width: 50px;
      text-align: center;
    }

    &-payment {
      width: 70px;
    }

    &-configuration {
      width: 150px;
    }

    &-course {
      width: 100px;
    }
  }
}

.cm-top {
  position: sticky;
  left: 0;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid $border-color;
}

.cm-actions {
  position: sticky;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid $border-color;

  > * {
    margin-bottom: 6px;
  }
}

.multiple-select-bg-color ::v-deep {
  .v-btn.primary--text {
    color: white !important;
  }

  .v-icon {
    color: white;
  }

  .select-all {
    color: white;
  }
}

.contents {
  display: contents;
}

.q-course-day {
  color: $title-second;
  font-weight: 500;
  font-size: 12px;
}
</style>
